@import "variables";

body {
    background-color: $osh-black;
    color: $osh-white;
    font-family: 'Satoshi', Courier, monospace;
    font-size: 16px;
    line-height: 1.5;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
}

body.page-scrollable {
    overflow: auto;
}

::selection {
    background: #000000;
    color: $osh-green;
}

::-webkit-scrollbar {
    width: 0.5rem;
}
  
::-webkit-scrollbar-track {
    background: $osh-black;
}

::-webkit-scrollbar-thumb {
    background: #000000;
    opacity: 0;
    transition: 1s cubic-bezier(0.6, 0.6, 0.6, 1);
    transition-delay: 1s;
}

.page-loaded {
    &::-webkit-scrollbar-thumb {
        opacity: 1;
    }
}

::-webkit-scrollbar-thumb:hover {
    background: $osh-green;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
}

hr {
    background-color: #FFFFFF;
    height: 2px;
    margin-bottom: 32px;
    margin-top: 0px;
}

canvas {
    left: 0;
    position: fixed;
    top: 0;
}

section,
footer {
    background-color: $osh-black;
    position: relative;
    z-index: 1;
}

.container {
    @media screen and (min-width: $desktop) {
        padding: 32px;
    }

    padding: 16px;
}

.section__heading {
    @media screen and (min-width: $desktop) {
        font-size: 64px;
    }

    font-size: 32px;
    font-weight: 700;
}

.section__text {
    @media screen and (min-width: $desktop) {
        font-size: 24px;
    }

    font-size: 16px;
    font-weight: 400;
}

.section__link {
    @media screen and (min-width: $desktop) {
        font-size: 24px;
    }

    color: $osh-white;
    cursor: pointer;
    border-bottom: 1px solid $osh-white;
    font-size: 16px;
    font-weight: 700;
    position: relative;
    transition: 0.3s cubic-bezier(0.6, 0.6, 0.6, 1);
    text-decoration: unset;
    white-space: nowrap;

    &:hover {
        border-bottom: 1px solid $osh-green;
        color: $osh-black;

        &::after {
            transform: scale3d(1, 1, 1);
            transform-origin: center bottom;
        }
    }

    &::after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background-color: #69FF69;
        transform: scale3d(1, 0, 1);
        transform-origin: center top;
        transition: transform .3s cubic-bezier(0.6, 0.6, 0.6, 1);
        z-index: -1;
    }
}

.section__link--svg {
    color: $osh-white;
    cursor: pointer;
    padding: 4px 8px;
    position: relative;
    transition: 0.3s cubic-bezier(0.6, 0.6, 0.6, 1);
    text-decoration: unset;

    svg,
    path {
        fill: $osh-white;
        transition: transform .3s cubic-bezier(0.6, 0.6, 0.6, 1);
    }

    &:hover {
        color: $osh-black;

        svg,
        path {
            fill: $osh-black;
        }

        &::after {
            transform: scale3d(1, 1, 1);
            transform-origin: left bottom;
        }
    }

    &::after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background-color: #69FF69;
        transform: scale3d(0, 0, 1);
        transform-origin: right top;
        transition: transform .3s cubic-bezier(0.6, 0.6, 0.6, 1);
        z-index: -1;
    }
}

.coming-soon {
    cursor: not-allowed;
}

.color-green {
    color: $osh-green;
}

/* Font Weight Classes */

.fw-400 {
    font-weight: 400;
}

.fw-700 {
    font-weight: 700;
}

/* Margin Classes */

.mb-0 {
    margin-bottom: 0;
}

.mb-16 {
    margin-bottom: 16px;
}

.mb-32 {
    margin-bottom: 32px;
}

.mt-n2 {
    margin-top: -2px;
}

.mr-auto {
    margin-right: auto;
}

.mr-16 {
    margin-right: 16px;
}

.mr-32 {
    margin-right: 32px;
}

.my-auto {
    margin-bottom: auto;
    margin-top: auto;
}

/* Display Classes */

.d-flex {
    display: flex;
}

.d-none {
    display: none;
}

.flex-column {
    flex-direction: column;
}

/* Width Classes */

.w-50 {
    width: 50%;
}

.section__border {
    background-color: #FFFFFF;
    height: 2px;
    width: 100%;
    z-index: 1;
}

@media screen and (min-width: $desktop) {
    .flex-lg-row {
        flex-direction: row;
    }

    mb-lg-0 {
        margin-bottom: 0;
    }

    .mr-lg-16 {
        margin-right: 1rem;
    }

    .order-lg-1 {
        order: 1;
    }

    .order-lg-2 {
        order: 2;
    }
    
    .w-lg-30 {
        flex: 0 0 30%;
        width: 30%;
    }

    .w-lg-40 {
        flex: 0 0 40%;
        width: 40%;
    }

    .w-lg-50 {
        flex: 0 0 50%;
        width: 50%;
    }
    
    .offset-lg-10 {
        margin-left: 10%;
    }

    .offset-lg-20 {
        margin-left: 20%;
    }
}
