@import 'variables';

.section__banner {
    background-color: transparent;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    
    canvas {
        background-attachment: fixed;
        background-position: center;
        background-repeat: no-repeat;
        height: 100;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 0;
    }

    .container {
        position: relative;
        z-index: 10;
    }

    .section__border {
        transition: 1s cubic-bezier(0.6, 0.6, 0.6, 1);
        width: 0%;
    }

    h1,
    p {
        transition: 1s cubic-bezier(0.6, 0.6, 0.6, 1);
        transition-delay: 1s;
        opacity: 0;
    }

    .section__banner--loader {
        background-color: $osh-black;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        transition: 1s cubic-bezier(0.6, 0.6, 0.6, 1);
        transition-delay: 2.5s;
        width: 100%;
        z-index: 5;
    }
}

.page-loaded {
    .section__banner {
        .section__border {
            width: 100%;
        }

        h1,
        p {
            opacity: 1;
        }

        .section__banner--loader {
            background-color: transparent;
        }
    }
}
