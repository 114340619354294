@import "variables";

.section__projects {
    margin-top: 100vh;

    svg {
        @media screen and (min-width: $desktop) {
            display: block;
        }

        display: none;
    }

    .project {
        @media screen and (min-width: $desktop) {
            margin-right: unset;
            width: 50%;
        }
    
        margin-right: auto;
        padding-left: 0;
        transition: .3s cubic-bezier(0.2, 0.6, 0.3, 1);
        width: unset;
    }
    
    .role {
        @media screen and (min-width: $desktop) {
            margin-right: auto;
        }
    
        margin-right: unset;
    }
}

.section__projects--link {
    color: #FFFFFF;
    position: relative;
    text-decoration: unset;
    transition: .3s cubic-bezier(0.2, 0.6, 0.3, 1);
}

.section__projects--link::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: #69FF69;
    transform: scale3d(1, 0, 1);
    transform-origin: center top;
    transition: transform .3s cubic-bezier(0.2, 0.6, 0.3, 1);
    z-index: 1;
}



.section__projects--link:hover::after {
    transform: scale3d(1, 1, 1);
    transform-origin: center bottom;
}

.section__projects--link:hover {
    color: #232323;
}

.section__projects--link:hover .project {
    padding-left: 16px;
}

.section__projects--link-container {
    padding-bottom: 16px;
    padding-top: 16px;
    position: relative;
    z-index: 2;
}